@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
}

:root {
  // --primaryClr: #ffbd00;
  // --primaryClr: #0cd664;
  --primaryClr: #ffb900;
  --secondaryClr: #222a34;
  // --headClr: #101520;
  --headClr: #1a212a;
  // --bodyClr: #1a1e29;
  --bodyClr: #0b151e;
  --whiteClr: #ffffff;
  --blackClr: #111;
  --lightClr: rgba(255, 255, 255, 0.6);
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

html,
body {
  background: var(--bodyClr);
  color: var(--whiteClr);
  font-size: 16px;
  // font-family: "Roboto", sans-serif;
  font-family: Averta Std, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  overflow-x: hidden;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  transition: all 0.3s;
}
.btn-primary {
  background: var(--primaryClr);
  color: var(--whiteClr);
  border-color: var(--primaryClr);
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 50px;
  padding: 0.425em 1.1875em;
  &:hover,
  &:focus {
    background: var(--primaryClr) !important;
    color: var(--whiteClr) !important;
    border-color: var(--primaryClr) !important;
  }
}

.btn-login::after {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background: linear-gradient(90deg, #45abf1 0%, #44b623 100%);
  box-shadow: -4px -4px 10px #3c3c3c, 5px 5px 5px #111111;
  border-radius: 30px;
  z-index: -1;
  display: inline-block;
}

.btn-outline-primary {
  border-color: var(--primaryClr);
  &:hover,
  &:focus {
    background: transparent !important;
    color: #000000 !important;
    opacity: 0.9;
    border-color: var(--primaryClr) !important;
  }
}
.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 8px;
}
.back-link,
.back_btn {
  color: var(--primaryClr);
  font-size: 12px !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
  border: 1px solid var(--primaryClr);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover,
  &:focus {
    background: var(--primaryClr);
    color: var(--blackClr);
  }
}
img {
  width: 100%;
  height: auto;
}
input,
input.form-control {
  border-radius: 0;
  // border-color: var(--headClr);
  border-color: #67717e !important;
  background-color: #101924 !important;
  font-size: 14px;
  font-weight: 300;
  &:focus {
    border-color: var(--headClr);
    box-shadow: none !important;
  }
  &[type="number"] {
    appearance: textfield;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--bodyClr) inset !important;
  -webkit-text-fill-color: var(--whiteClr) !important;
  border-radius: 0 !important;
}
.header_open {
  .navbar {
    transform: translateX(0px) !important;
    .navbar-toggler {
      right: -12px !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .navbar-collapse {
    max-height: calc(100vh - 15px);
    overflow-y: auto;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  // background: var(--blackClr);
  width: 100%;
  transition: all 0.3s;
  .top-head {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 40px;
    margin-top: 0;
    background-color: var(--secondaryClr1);
    transition: margin-top 0.2s;
    .tophead-left ul {
      display: flex;
      align-items: center;
      height: 40px;
      li a {
        display: flex;
        margin-right: 30px;
        align-items: center;
        img {
          width: 14px;
          height: 14px;
        }
        p {
          margin: 0;
          font-size: 12px;
          margin-left: 8px;
          color: #fff;
          font-weight: 600;
        }
      }
    }
    .tophead-right {
      display: flex;
      align-items: center;
      .time-date span {
        color: #fff;
        margin-right: 30px;
        font-size: 12px;
        font-weight: 700;
      }
      .language select {
        background: transparent;
        border: 0;
        color: #fff;
        padding: 0;
        font-size: 14px;
        &:focus-visible {
          outline: 0;
        }
        option:checked,
        option:hover {
          border-bottom: 1px solid var(--secondaryClr);
          background: #172c3f;
          border: 1px solid var(--secondaryClr);
        }
        option {
          border-bottom: 1px solid var(--secondaryClr);
          background: #172c3f;
          border: 1px solid var(--secondaryClr);
        }
      }
    }
  }
  .nav-head {
    position: relative;
    height: 60px;
    background-color: var(--whiteClr);
    border-radius: 10px;
    transition: background-color 0.2s;
    padding: 0 15px;
    margin: 0 25px;
    .navbar a {
      padding: 0;
      font-size: 14px;
      color: var(--blackClr);
      font-weight: 700;
      margin-right: 25px;
      text-transform: uppercase;
    }
  }
  .logo {
    max-width: 145px;
    margin-right: 30px;
  }

  .headerRight {
    display: flex;
    align-items: center;
    fill: var(--whiteClr);
    ul li svg {
      fill: #000;
    }
    .funds {
      .dropdown {
        button {
          border: 0 !important;
          &:hover {
            border: 0 !important;
          }
        }
        .dropdown-menu {
          background: var(--headClr);
          padding: 8px;
          position: relative;
          a {
            border-radius: 5px;
            padding: 5px;
            background: #333f4e;
            margin-bottom: 10px;
            &:hover {
              background: #333f4e;
            }
            .currency-head {
              height: 40px;
              display: flex;
              align-items: center;
              .currency-section {
                display: flex;
                align-items: center;
                border-radius: 5px;
                padding: 5px;
                .currency-sign {
                  width: 35px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 5px 0;
                  border-right: 1px solid #08f73a;
                  span {
                    font-size: 30px;
                    line-height: inherit;
                    color: #08f73a;
                  }
                }
                .currency-name {
                  padding-left: 10px;
                  display: flex;
                  flex-direction: column;
                  font-size: 13px;
                  line-height: 18px;
                }
              }
              .active {
                background: #333f4e;
              }
            }
          }
        }
      }
    }
    .path-stroke {
      stroke: var(--whiteClr);
    }
    .funds ul {
      // background: var(--secondaryClr);
      border-radius: 25px;
      padding: 4px 12px;
      margin-right: 12px;
      li {
        position: relative;
        a {
          color: var(--whiteClr);
          display: flex;
          align-content: center;
        }
      }
    }

    ul {
      display: flex;
      align-items: center;
      li {
        margin: 0 5px;
        position: relative;
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .menu-btn {
      background: rgba(255, 255, 255, 0.08);
      width: 35px;
      height: 35px;
      line-height: 35px;
      padding: 0;
      margin-left: 5px;
      border: none;
      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.08) !important;
        border-color: transparent !important;
      }
      svg {
        width: 15px;
        height: auto;
        fill: var(--primaryClr);
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--primaryClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    margin-left: 5px;
  }
}
.offcanvas {
  border: none !important;
  z-index: 9999999999;
}
.offcanvas-header {
  background: var(--headClr);
  color: var(--whiteClr);
  .profile-img {
    width: 48px;
    height: 48px;
    background: var(--bodyClr);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: var(--whiteClr);
  }
  .user-info {
    color: var(--whiteClr);
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin: 0;
    }
  }
  .btn-close {
    opacity: 1;
  }
}
.offcanvas-body {
  background: var(--bodyClr);
  .balance {
    font-size: 16px;
    color: var(--whiteClr);
    display: flex;
    align-items: center;
    svg {
      margin-right: 14px;
      fill: var(--primaryClr);
      stroke: var(--primaryClr);
    }
  }
  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: var(--whiteClr);
  }
  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }
  .menu-items {
    border-top: 1px solid var(--headClr);
    margin-top: 15px;
    li {
      border-bottom: 1px solid var(--headClr);
      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: var(--whiteClr);
        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
        }
      }
    }
  }
  ul.social_links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    li {
      margin: 0 10px;
      position: relative;
      svg {
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        cursor: pointer;
      }
      .notification-count {
        width: 12px;
        height: 12px;
        background: var(--siteRedClr);
        color: var(--whiteClr);
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        font-size: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.form-steps {
  text-align: center;
}
.mainBanner {
  .banner_info {
    background: radial-gradient(
      43.89% 132.14% at 50% 43.3%,
      rgb(19, 121, 70) 0%,
      rgb(26, 33, 42) 100%
    );
    width: 100%;
    min-height: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2rem;
    border-radius: 12px;
    .payment {
      display: flex;
      li {
        max-height: 36px;
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px 1rem;
        img {
          width: 100%;
          max-height: inherit;
          vertical-align: middle;
        }
      }
    }
  }
  .slick-dots {
    @media screen and (max-width: 767.98px) {
      bottom: -15px;
    }
    bottom: 0px;
    li {
      button {
        &::before {
          color: var(--primaryClr);
          font-size: 13px;
        }
      }
    }
  }
}
.main {
  transition: all 0.3s;
  .TopCategories {
    margin: 0 0px 30px;
    position: relative;
    .casino_headeing {
      position: absolute;
      top: 0;
      left: 0;
      width: 140px;
      height: 70px;
      line-height: 70px;
      background: var(--secondaryClr);
      color: var(--whiteClr);
      text-align: center;
      border-right: 2px solid var(--primaryClr);
    }
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 0 0 140px;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("https://gbet567.com/images/sports.png");
          }
        }
      }
    }
    .tab-content {
      max-height: 300px;
      overflow: hidden;
      &.view_more {
        max-height: fit-content !important;
      }
    }
  }
  .games_tab {
    padding: 20px 0;
    ul {
      display: flex;
      overflow: auto hidden;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 10px;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: rgb(76, 81, 89);
        }
      }
      li {
        flex: 0 0 auto;
        margin-left: 0.65rem;
        &:first-child {
          margin-left: 0;
        }
        a {
          display: inline-block;
          color: rgb(220 220 220);
          position: relative;
          &.active,
          &:hover {
            color: var(--whiteClr);
          }
          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 2px;
            background: var(--primaryClr);
            opacity: 0;
            transition: all 0.3s;
          }
          &.active {
            &:after {
              opacity: 1;
            }
          }
          .imgBox {
            width: 66px;
            height: 66px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            @media only screen and (max-width: 767px) {
              width: 56px;
              height: 56px;
            }
            img {
              background: var(--headClr);
              width: 100%;
              height: auto;
              line-height: 0;
              padding: 1rem;
              border-radius: 25%;
              text-align: center;
              display: inline-block;
              font-size: 1.5rem;
              box-sizing: content-box;
            }
          }
          .text {
            font-size: 13px;
            max-width: 65px;
            white-space: normal;
            text-align: center;
            line-height: 1rem;
            margin-top: 0.5rem;
            letter-spacing: -0.1px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .sectionTitle {
    color: #fff;
    margin-bottom: 26px;
    margin-top: 15px;
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: var(--headClr);
    margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input {
      position: relative;
      z-index: 9 !important;
      padding-right: 7px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    button.calender-icon {
      position: absolute;
      right: 0;
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    h3 {
      border-bottom: 1px solid var(--whiteClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .input-group {
      .btn-primary {
        padding: 6px 10px;
        border-left: 0;
        border-color: var(--headClr) !important;
        border-radius: 0;
      }
    }
    ul {
      margin: 0 -1rem;
      border-top: 1px solid var(--whiteClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--whiteClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }
  .footer-content {
    margin-top: 20px;
    h3 {
      color: #0cd664;
      font-size: 20px;
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primaryClr);
    }
    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .edit-btn {
    background: var(--primaryClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--headClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: var(--headClr);
      color: var(--whiteClr);
      border-color: #1a212a;
    }
    td {
      background: var(--bodyClr);
      color: var(--whiteClr);
      border-color: #1a212a;
    }
    button.btn-primary {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }
  }
}
.changePasswordModal {
  .modal-content {
    background: var(--headClr);
    .modal-header {
      border-color: #6c757d;
      button {
        background: transparent url(../public/images/cross-23.svg) 50%/1em auto
          no-repeat;
      }
    }
    .modal-footer {
      border-color: #6c757d;
      button.btn-secondary {
        border-radius: 50px;
      }
    }
  }
}
.listings {
  a {
    color: var(--primaryClr);
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
  }
  .filter {
    margin-bottom: 20px;

    .dropdown-toggle {
      background: var(--primaryClr) !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }
    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: transparent;
      border-radius: 0 !important;
    }
    .dropdown-menu {
      border-radius: 0;
      border: 1px solid var(--whiteClr);
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;
      a {
        border-bottom: 1px solid var(--whiteClr);
        color: var(--headClr);
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;
        &:last-child {
          border: none;
        }
      }
    }
  }
  .like-btn {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;
    display: none;
    svg {
      fill: var(--whiteClr);
    }
  }
}
.steps-canvas {
  .offcanvas-header {
    background: var(--headClr);
    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: var(--whiteClr);
    }
  }
  .form-steps {
    padding: 55px 30px 0;
    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: var(--whiteClr);
    }
    p {
      font-size: 18px;
      font-weight: 300;
      color: var(--whiteClr);
    }
    input {
      background: var(--headClr);
      border-radius: 10px;
      border: 1px solid !important;
      border-color: var(--headClr) !important;
      padding: 14px 20px;
      color: var(--whiteClr);
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: var(--whiteClr);
    }
    .flag-dropdown {
      background: transparent;
      border: 0;
      border-right: 1px solid var(--headClr);
      &.open,
      &:focus {
        background: transparent;
      }
      .selected-flag {
        background: transparent !important;
      }
      .country-list {
        background: var(--bodyClr) !important;
        overflow-y: auto;
        width: 308px;
        margin: 10px 0;
        text-align: left;
        @media only screen and (max-width: 767.98px) {
          width: 268px;
        }
        .country {
          // display: none;
          &:first-child {
            display: block !important;
          }
          &.highlight,
          &:hover {
            background: var(--headClr) !important;
          }
        }
      }
    }
    .btn {
      padding: 9px 30px;
    }
    .form-link {
      font-size: 14px;
      color: var(--lightClr);
      text-decoration: underline;
      &:hover {
        opacity: 0.7;
      }
    }
    .sign-up {
      font-size: 14px;
      font-weight: 500;
      a {
        font-size: 16px;
        color: var(--primaryClr);
        text-decoration: underline;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
.deposit ul {
  li {
    button {
      &.active {
        background: #0cd664 !important;
      }
    }
  }
}
.tabs-area {
  margin-top: 10px;
  ul {
    flex-wrap: nowrap;
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid var(--whiteClr);
      }
      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;
        &.active {
          background: var(--headClr);
          color: var(--whiteClr);
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: var(--secondaryClr);
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
      .deposit-head {
        text-align: center;
        padding: 0;
        background: #fff;
        border-radius: 0;
        width: 31.33%;
        margin: 0 6px 15px;
        height: auto;
        .deposit-img {
          padding: 20px;
          img {
            max-width: 300px;
            height: 100px;
            @media only screen and (max-width: 767px) {
              height: auto;
            }
          }
        }
        .bkash {
          img {
            width: 100%;
          }
        }
        .deposit-name {
          background-color: #1ea0f8;
          height: 60px;
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }
      .top-area {
        background: var(--headClr);
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }
      .bottom-area {
        padding: 20px;
        table {
          margin-bottom: 0;
          th {
            color: var(--whiteClr);
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }
          td {
            color: var(--whiteClr);
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
          }
        }
      }
    }
    .upi-list {
      margin-top: 40px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }
    form {
      padding: 15px 30px 0;
      text-align: center;
      input {
        background: var(--headClr);
        border-radius: 10px;
        border-color: var(--headClr);
        padding: 14px 20px;
        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid var(--primaryClr);
          padding: 0;
          background-color: var(--whiteClr);
          position: relative;
          &:checked:after {
            content: "";
            background: var(--headClr);
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }
      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
      }
      .radio-btns {
        display: flex;
        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }
    .card-holder {
      height: 80px;
      background: #1a212a;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;
      img {
        max-width: 100px;
      }
    }
  }
  .h4 {
    font-size: 14px;
    font-weight: 500;
  }
  .sectionTitle {
    &::after {
      background: var(--headClr);
      width: 100%;
    }
  }
}
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--secondaryClr);
    margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 5px;
        color: var(--whiteClr);
        font-weight: 500;
        border: none;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      margin: 0 0 10px;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: var(--primaryClr);
        color: var(--blackClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 10px;
          color: #838383;
        }
      }
      .status {
        background: var(--primaryClr);
        color: var(--blackClr);
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }
      .listing {
        display: flex;
        flex-wrap: nowrap;
        li {
          margin: 0 2px;
          width: 100%;
          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--blackClr);
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;
            cursor: pointer;
            span {
              font-size: 12px;
            }
            &.yes {
              background: var(--oddsBack);
            }
            &.no {
              background: var(--oddsLay);
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.page-details {
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 10px 1rem;
    .date {
      text-align: center;
      color: var(--primaryClr);
      font-size: 12px;
      margin-bottom: 5px;
    }
    .title {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
    .range {
      background: var(--primaryClr);
      font-size: 11px;
      color: var(--blackClr);
      padding: 2px 5px;
      line-height: normal;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 14px;
      padding: 6px 1rem;
      display: inline-block;
      color: var(--blackClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 8px;
        line-height: normal;
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--blackClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
  }
  .game-points {
    .back-lay-section {
      background-color: #3b5160 !important;
      border-bottom: 1px solid #5c5c5c;
      .title {
        background-color: #3b5160 !important;
        margin: 0 !important;
      }
      .back-lay-points {
        margin-left: auto;
        background-color: #3b5160 !important;
        ul {
          height: 100%;
          background-color: #3b5160 !important;
          align-items: center;
          li {
            width: 68px;
            border: 0 !important;
            &:nth-child(3) {
              line-height: normal;
              font-size: 10px;
              margin: 4px 2px 4px 2px;
              border-radius: 2px;
              padding: 3px 2px;
              background: var(--oddsBack);
              display: flex;
              justify-content: center;
              color: var(--blackClr);
              height: 20px;
            }
            &:nth-child(4) {
              line-height: normal;
              font-size: 10px;
              margin: 4px 2px 4px 2px;
              border-radius: 2px;
              padding: 3px 2px;
              background: var(--oddsLay);
              display: flex;
              justify-content: center;
              color: var(--blackClr);
              height: 20px;
            }
          }
        }
      }
    }
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 3px 0 3px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
        }
        .team-name {
          display: flex;
          flex-direction: column;
          line-height: normal;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 4px;
            left: 4px;
            width: 95%;
            background: rgba(0, 0, 0, 0.7);
            font-size: 12px;
            color: var(--whiteClr);
            text-align: center;
            border-radius: 2px;
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin-left: 4px;
          border-radius: 2px;
          padding: 5px 2px;
          width: 60px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}
.footer {
  transition: all 0.3s;
  padding-bottom: 0;
  margin-top: 100px;
  background: #0f1d29;
  .upi-provider {
    border-top: 1px solid #0f1d29;
    padding: 15px 0;
    background-color: #0b151e;
    ul {
      display: flex;
      justify-content: center;
      li a img {
        max-height: 20px;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        margin: 6px 12px;
        filter: grayscale(100%) brightness(50%) contrast(0);
        opacity: 0.6;
        transition: 0.3s ease-in-out;
        &:hover {
          filter: inherit;
        }
      }
    }
  }
  p {
    color: var(--whiteClr);
    opacity: 0.6;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
  .footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
  }
  .paymentImages,
  .secureGaming {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      li {
        padding: 8px;
        img {
          max-height: 40px;
          // filter: grayscale(1);
          transition: all 0.2s;

          &:hover {
            filter: none;
          }
        }
      }
    }
  }
  .footer-links {
    padding: 40px 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .betlinks {
      margin-left: 64px;
      h4 {
        color: var(--primaryClr);
      }
      ul {
        text-align: left;
        display: flex;
        flex-direction: column;
        li {
          font-size: 14px;
          font-weight: 600;
          display: inline-block;
          position: relative;
          color: #fff;
          margin: 3px 0px;
          &:after {
            content: " ";
            position: absolute;
            right: -16px;
            top: 2px;
            width: 1px;
            height: 14px;
            background: var(--headClr);
          }
          &:last-child {
            margin-right: 0;
            &::after {
              display: none;
            }
          }
          a {
            color: var(--whiteClr);
          }
        }
      }
    }
  }
  .social-icons {
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-right: 15px;
        position: relative;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 40px;
          height: auto;
          cursor: pointer;
        }
        svg {
          width: 32px;
        }
        .notification-count {
          width: 12px;
          height: 12px;
          background: var(--siteRedClr);
          color: var(--whiteClr);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: -2px;
          font-size: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .footer-menu {
    background-color: var(--headClr);
    box-shadow: 0 0px 14px rgb(255 189 0 / 12%);
    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        a {
          padding: 15px 5px 10px 5px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;
          cursor: pointer;
          svg,
          img {
            fill: currentColor;
            stroke: currentColor;
            max-width: 22px;
            max-height: 22px;
          }
          span {
            margin-top: 5px;
          }
          &.active {
            color: var(--primaryClr);
            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }
      }
    }
  }
}
.agent-head {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  border-radius: 6px;
  padding: 30px;
  margin-top: 15px;
  .social-icon {
    width: 12%;
    margin: 0 2%;
    img {
      width: 100%;
    }
  }
}
.live_casino_page {
  // padding-top: 50px;

  // &.ezugi {
  //   background: rgb(35, 31, 32);
  // }
  &.live {
    background: var(--headClr);
    color: var(--whiteClr);
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: var(--primaryClr);
      .path-stroke {
        stroke: var(--primaryClr);
      }
    }
  }

  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}
.form-control {
  background: transparent !important;
  color: var(--whiteClr) !important;
  &:focus {
    border-color: var(--primaryClr);
  }
}
.section-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  h2 {
    margin-bottom: 0 !important;
  }
  a {
    background: var(--primaryClr);
    color: var(--blackClr);
    font-size: 15px;
    font-weight: 600;
    font-family: sans-serif;
    height: 40px;
    margin-left: 8px;
    padding: 10px 20px;
    border: 0;
    border-radius: 25px;
    transition: all 0.4s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
}

.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid var(--secondaryClr);
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: var(--primaryClr);
        color: var(--blackClr);
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }
    .cancel-btn {
      background: var(--siteRedClr);
      color: var(--blackClr);
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.live_dealer_casino {
  padding: 15px 0;
  background: #1a212a;
  margin-bottom: 20px;
  box-shadow: 1px 2px 11px rgb(0 0 0 / 68%);
  h4 {
    a {
      margin-bottom: 25px;
      font-size: 22px;
      font-weight: bold;
      display: block;
      color: #fff;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        width: 70px;
        height: 1px;
        background-color: #fff;
        bottom: -4px;
      }
    }
  }
}
.populargames {
  a {
    padding: 0 5px;
    margin-bottom: 0 !important;
  }
}
.mybets h3 {
  display: none;
}
.casino_games_type {
  padding: 20px 0;
  .listings {
    margin-bottom: 30px;
    .text {
      margin: 0 0 10px;
    }
    .headinggame {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a.gamemore {
        background-color: var(--primaryClr);
        color: var(--blackClr);
        padding: 5px;
        border-radius: 5px;
        font-size: 15px;
      }
    }
    .Evolution_Games {
      display: flex;
      justify-content: start;
      overflow-x: auto;
      padding-bottom: 5px;
      scrollbar-color: var(--headClr) var(--secondaryClr);
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #585858;
      }
      &::-webkit-scrollbar-thumb {
        background: #2d3136;
        &:hover {
          background: #2d3136;
        }
      }
      .casino_games {
        width: calc(12.5% - 10.5px);
        min-width: calc(12.5% - 10.5px);
        margin-right: 12px;
        &:last-child {
          margin-right: 0;
        }

        .shadow.rounded.position-relative {
          box-shadow: 0px 0px 0px var(--blackClr) !important;
          text-align: center;
          img {
            width: 100%;
            height: auto;
            border-radius: 12px;
          }
        }
      }
    }
  }
}

.provider_sec {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    li {
      max-width: 150px;
      height: auto;
      margin: 0 5px 10px;
      img {
        border-radius: 7px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .header_open .exchange_iframe {
    margin-left: 230px;
    width: calc(100% - 230px);
    transition: all 0.3s;
  }
  .header_open .container_contact {
    width: calc(100% - 242px);

    transition: all 0.3s;
    margin-left: 242px;
  }
  .container_contact h3 {
    margin-left: 30px;
  }
  .header_open {
    .header,
    .main,
    .footer,
    .iframe_sec,
    .ezugi_iframe {
      // width: calc(100% - 230px);
      // margin-left: 230px;
      transition: all 0.3s;
    }
    header {
      .logo {
        // display: none;
      }
    }
  }
}

@media only screen and (max-width: 1199.98px) {
  .casino_games_type .listings .Evolution_Games .casino_games {
    width: calc(16.67% - 10px);
    min-width: calc(16.67% - 10px);
  }
  .main .TopCategories .tab-content {
    max-height: 330px;
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .mainBanner {
    .banner_info {
      flex-wrap: wrap;
      margin-top: 0;
      margin-bottom: 0.5rem;
      padding: 1rem;
      h2 {
        order: 0;
        font-size: 1rem;
      }
      .payment {
        order: 2;
        width: 100%;
        justify-content: center;
        margin-top: 1rem;
      }
      .btn-primary {
        order: 1;
      }
    }
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(25% - 9px);
          min-width: calc(25% - 9px);
        }
      }
    }
  }
}
.social-icons h3 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
}
.withdraw {
  label {
    width: 100%;
    text-align: left;
    p {
      color: #0cd664;
      margin-bottom: 6px;
    }
  }
  p.bank {
    color: #0cd664;
    margin-bottom: 6px;
  }
}
.withdraw_btn {
  a {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 767.98px) {
  .note-section {
    flex-direction: column;
    font-size: 11px;
    padding: 0 12px;
    display: flex;
  }
  .note {
    color: red;
    font-size: 14px;
  }
  .tron-qr-code.paytm {
    padding-right: 0 !important;
  }
  .tron-qr-section.paytm {
    flex-direction: column-reverse !important;
  }
  .tabs-area .tab-content .bank-details {
    flex-wrap: wrap;
  }
  .tabs-area .tab-content .bank-details .deposit-head {
    margin: 0 4px 10px;
    width: 47.33%;
  }
  .tabs-area .tab-content .bank-details .deposit-head .deposit-img {
    padding: 8px;
  }
  .tabs-area .tab-content .bank-details .deposit-head .deposit-name {
    height: 40px;
  }
  .tabs-area .tab-content .bank-details .deposit-head .deposit-name p {
    font-size: 13px;
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
    .games_tab {
      ul {
        &::-webkit-scrollbar-thumb {
          background: rgb(76, 81, 89);
        }
      }
    }
  }

  .agent-head {
    justify-content: space-between;
    .social-icon {
      width: 40%;
      margin: 2%;
    }
  }
  .header {
    &.aftrlgn {
      .logo {
        // margin-left: 35px;
      }
    }
    .navbar {
      .navbar-toggler {
        top: 15px;
        right: -37px;
        width: 30px;
        height: 30px;
        line-height: 16px;
        background: linear-gradient(135.97deg, #2c2b2b 17.8%, #0e0e0e 79.27%);
        padding: 0 0.15rem;
        border: 0.5px solid rgba(68, 68, 68, 1);
        border-radius: 50%;
        box-shadow: -2px -2px 8px #292929, 2px 2px 8px #070707;
        .navbar-toggler-icon {
          background-image: url("./assets/images/toggle-icon.svg");
          height: 20px;
          width: 18px;
        }
      }
    }
    .headerRight {
      .menu-btn {
        margin-left: 0;
      }
      .funds ul {
        margin-right: 0px;
        padding: 0px !important;
        background: inherit !important;
        li {
          margin: 0 4px !important;
          a {
            color: var(--whiteClr);
            background: var(--secondaryClr);
            display: block;
            border-radius: 22px;
            padding: 5px 12px !important;
            span {
              padding-left: 2px;
            }
          }
          .deposit-btn {
            background: var(--primaryClr) !important;
            color: var(--whiteClr) !important;
            font-size: 0.75rem;
            font-weight: 700;

            height: 35px;
            line-height: 35px;
            padding: 0 1em;
            border: 0;
            border-color: transparent;
            border-radius: 30px;

            &:hover,
            &:focus {
              background: #e5a600 !important;
            }
          }
        }
        li:after {
          display: none;
        }
      }
      ul {
        li {
          margin: 0 2px;
        }
      }
    }
  }
  .header_open {
    .header {
      .navbar {
        .navbar-toggler {
          right: 10px !important;
          .navbar-toggler-icon {
            background-image: url("./assets/images/close-icon.svg");
          }
        }
      }
    }
  }
  .tabs-area .tab-content .card-holder img {
    max-width: 80px;
  }
  .custom-tabs {
    padding-top: 0px;
  }
  .custom-tabs .sports-name-listing ul {
    padding: 5px;
    margin-bottom: 5px;
  }

  .page-details .game-points ul li .team-name {
    width: 100%;
    padding-left: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .page-details .game-points ul li .status-btns {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .page-details .game-points ul li .info-block {
    width: inherit;
    &:first-child {
      margin-left: 0;
    }
  }
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(33.33% - 8px);
          min-width: calc(33.33% - 8px);
        }
      }
    }
  }
  .ezugi_iframe {
    iframe {
      padding-top: 0 !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .tabs-area .tab-content .bank-details .deposit-head {
    width: 70.33%;
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 365px;
      }
    }
  }
  .live_dealer_casino {
    .casino_games {
      ul {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 521px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 330px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .casino_games_type {
    .listings {
      .Evolution_Games {
        .casino_games {
          width: calc(50% - 6px);
          min-width: calc(50% - 6px);
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 290px;
      }
    }
  }
}

@media only screen and (max-width: 421px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 265px;
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header {
    .headerRight {
      .funds {
        ul {
          li {
            a {
              display: flex;
              border-radius: 22px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  .main {
    .TopCategories {
      .tab-content {
        max-height: 250px;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .main {
    .TopCategories {
      .tab-content {
        max-height: 240px;
      }
    }
  }
}
.deposit-head {
  text-align: center;
  padding: 0;
  height: 300px;
  color: #fff;
  border-right: 1px solid #111111;
  &:hover {
    color: #fff;
  }
  p {
    margin-bottom: 0;
  }
}

.contact-section {
  display: flex;
  align-items: center;
  margin-top: 22px;
  margin-bottom: 22px;
  a {
    text-align: center;
    margin-right: 28px;
    display: block;
    img {
      width: 100px;
      height: 100px;
      @media only screen and (max-width: 767px) {
        width: 70px;
        height: 70px;
      }
    }
    p {
      color: #fff;
      margin-bottom: 0;
      font-size: 20px;
      padding-top: 5px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}
.modal.app-modal .modal-content {
  background: var(--headClr);
}
.modal-body {
  background: var(--headClr);
}
.app-img {
  width: 210px;
  margin: 0 auto;
}
.search-input {
  position: relative;
  button {
    position: absolute;
    top: 24px;
    right: 13px;
    border-radius: 0;
    height: 35px;
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
  }
}
.app-installed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .app-left-part {
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      padding: 0;
      width: 20px;
      height: 20px;
      border: 0;
      color: #7e7e7e;
      &:hover {
        background-color: transparent !important;
      }
    }
    .app-body {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      .app-logo {
        display: none;
      }
      .app-name {
        margin-bottom: 0;
        line-height: 20px;
        .app-rate svg {
          color: #ff8d00;
        }
        h5 {
          margin-bottom: 0;
          font-size: 15px;
          color: #169fff;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  button.installBtn {
    border: 0;
    color: var(--primaryClr);
    background: transparent;
  }
}
.DepositModal {
  .modal-content {
    border: 0;
    .modal-header {
      padding: 10px 20px;
      background: var(--headClr);
      border: 0;
      img {
        width: 40px;
      }
      button {
        background-image: url(./assets/svg/download-svg.svg);
      }
    }
    .modal-body {
      padding: 20px;
      background: var(--secondaryClr);
      border: 0;
      .deposit-section-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 220px;
        }
        p {
          margin-bottom: 0;
          color: #dfdfdf;
          font-size: 14px;
          font-weight: 600;
        }
      }
      .confirmBtn {
        a {
          width: 100%;
          margin-top: 20px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        }
      }
    }
  }
}
.copy-name {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 12px;
  border: 1px solid var(--headClr);
  border-radius: 5px;
  padding: 10px 15px;
  h5 {
    margin-bottom: 0;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
  }
  p {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
    text-align: right;
    svg {
      margin-left: 8px;
    }
    p {
      font-size: 12px;
    }
  }
}
.deposit-form {
  input {
    border-radius: 10px;
    border-color: var(--headClr);
    padding: 14px 20px;
  }
}
.payment-button {
  button {
    border-radius: 50px;
  }
  button.btn-danger {
    margin-left: 15px;
  }
}
.deposit-modal-content .bank-details {
  display: flex;
  justify-content: center;
}
.deposit-modal-content .bank-details .deposit-head {
  text-align: center;
  padding: 0;
  background: #fff;
  color: #fff;
  border-radius: 0;
  width: 49%;
  margin: 0 6px 15px;
  height: auto;
}
.deposit-modal-content .bank-details .deposit-head .deposit-img {
  padding: 20px;
}
.deposit-modal-content .bank-details .deposit-head .deposit-img img {
  max-width: 70px;
}
.deposit-modal-content .bank-details .deposit-head .deposit-name {
  background-color: #1ea0f8;
  height: 60px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 576px) {
  .binanceModal .modal-dialog {
    max-width: 800px;
  }
}
.tron .sectionTitle {
  display: flex;
  align-items: center;
  &::after {
    display: none;
  }
}
.tron .sectionTitle img {
  max-width: 70px;
  margin-right: 0px;
}
.tron-section {
  max-width: 500px;
  margin: 30px auto 0;
  background: var(--headClr);
  box-shadow: 2px 5px 8px rgb(0 0 0 / 55%);
  .tron-heading {
    text-align: center;
    padding: 15px 0;
    background: #1a212a;
    img {
      max-width: 100px;
      margin: 0 auto;
      text-align: center;
    }
  }
  .tron-content {
    padding: 20px;
    background: #101215;
    p {
      span {
        color: red;
      }
    }
    button {
      width: 100%;
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
.tron-qr-section {
  padding: 20px;
  background: #101215;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding: 8px;
  }
  .tron-form {
    width: 100%;
    margin-right: 15px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .tron-qr-code {
    display: flex;
    flex-direction: column;
    width: 40%;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-top: 15px;
    }
  }
  .tron-qr-content {
    .copy-name {
      border: 1px solid var(--headClr);
      background: #000;
      display: flex;
      flex-direction: column;
      padding: 8px;
      @media only screen and (max-width: 991px) {
        flex-direction: column;
        padding: 10px 0;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
      }
    }

    svg {
      margin-left: 8px;
      color: #0cd664;
    }
  }
  .qr-code-img {
    img {
      max-width: 210px;
      @media only screen and (max-width: 991px) {
        margin-left: 10px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .tron-qr-section .tron-qr-content .copy-name > div {
    flex-direction: column;
  }
  .tron-qr-section .tron-qr-content .copy-name span {
    width: 90% !important;
  }
}
.exch-funds-form {
  .trigger {
    display: flex;
    justify-content: center;
    width: fit-content;
    border-radius: 25px;
    padding: 10px 25px;
    margin-left: auto;
    cursor: pointer;
    background: #14a2fe;
    margin-right: 20px;
    font-size: 16px;
    color: #ffffff;
    border-color: var(--primaryClr);
    font-weight: 700;
    svg {
      display: none;
    }
  }
}
.casino-images-section {
  display: flex;
  flex-wrap: wrap;
  .casino-images {
    width: 14.5%;
    margin: 1%;
    background: #323336;
    border: 2px solid #323336;
    border-radius: 5px;
    @media only screen and (max-width: 991px) {
      width: 18%;
    }
    @media only screen and (max-width: 767px) {
      width: 23%;
    }
    @media only screen and (max-width: 575px) {
      width: 48%;
    }
    img {
      padding: 8px 8px 0;
    }
    p {
      color: #fff;
      text-align: center;
      margin-bottom: 0;
      padding: 5px 0;
      font-size: 14px;
    }
  }
}
.Evolution_Games {
  ul {
    li {
      .row {
        flex-wrap: inherit;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .test {
    max-height: calc(100vh - 150px) !important;
    height: calc(100vh - 150px) !important;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .test {
    max-height: calc(100vh - 65px) !important;
    height: calc(100vh - 65px) !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .test {
    height: calc(100vh - 170px) !important;
    max-height: calc(100vh - 170px) !important;
  }
}
.page-content {
  h3 {
    color: #fff;
    margin-bottom: 26px;
    margin-top: 15px;
  }
  .contact-content {
    align-items: center;
    display: flex;
    img {
      height: 60px;
      margin-right: 20px;
      width: 60px;
    }
  }
}
.whtasapp-all-page {
  position: fixed;
  bottom: 85px;
  right: 15px;
  z-index: 9;
  width: 45px;
  height: 45px;
  img {
    width: 40px;
    box-shadow: 0 0 10px 5px #4242428f;
  }
}
.transfer-form {
  input {
    color: #fff;
    height: 30px;
    border: 0;
  }
}

.mybets-tabs-section {
  ul {
    width: max-content;
    display: flex;
    border: 1px solid #545454;
    border-top: 1px solid #545454 !important;
    margin-bottom: 20px !important;
    @media only screen and (max-width: 767px) {
      overflow-x: scroll;
      width: auto;
      margin: 15px 0px !important;
    }
    li {
      padding: 8px 15px !important;
      border-bottom: 0 !important;
      border-right: 1px solid #545454;
      white-space: nowrap;
      &:hover {
        background-color: var(--primaryClr);
      }
      &.active {
        background-color: var(--primaryClr);
      }
      div {
        color: #fff;
      }
    }
  }
}

.middleContentSec {
  width: 100%;
  @media only screen and (min-width: 1200px) {
    width: calc(100% - 600px);
    max-height: calc(100vh - 63px);
    overflow-y: auto;
    padding: 0 5px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: calc(100% - 500px);
    }
  }
}

.sports_page_banner {
  .slick-slider {
    img {
      border-radius: 5px;
      height: 220px;
      object-fit: cover;
    }
    .slick-dots {
      bottom: 8px;
      li {
        height: 3px;
        button {
          height: 3px;
          padding: 0;
          &:before {
            background: rgba(255, 255, 255, 0.7);
            color: transparent;
            width: 25px;
            height: 3px;
            opacity: 1;
          }
        }
        &.slick-active {
          button {
            &:before {
              background: var(--primaryClr);
            }
          }
        }
      }
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li.suspended {
            position: relative;
            display: flex;
            width: 100%;
            p {
              position: absolute;
              margin: 0;
              color: #b91616;
              -webkit-backdrop-filter: blur(10px);
              backdrop-filter: blur(10px);
              background: rgba(231 238 255 / 0.8);
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: bold;
              border-radius: 4px;
            }
          }
          li {
            margin-right: 5px;
            width: 44px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: var(--primaryClr);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #222;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: var(--headClr);
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid var(--headClr);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 32px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.rightbarSec {
  background: var(--secondaryClr);
  width: 400px;
  height: calc(100vh - 52px);
  overflow-y: auto;
  padding: 10px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
    width: 300px;
  }
  @media only screen and (max-width: 1199.98px) {
    display: none;
  }
  h6 {
    color: var(--primaryClr);
    font-size: 12px;
    font-weight: 600;
    line-height: 28px;
    padding: 5px 10px;
    border-bottom: 1px solid var(--primaryClr);
  }
  .VSlider {
    background-color: var(--headClr);
    border-radius: 10px;
    padding: 20px 10px;
    margin: 14px 0;
    .slick-slider {
      position: relative;
      .slide_box {
        width: 70%;
        margin: 0 auto;
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.45);
          border-radius: 5px;
        }
      }
      .slick-dots {
        top: 40%;
        bottom: auto;
        right: -40px;
        left: auto;
        transform: rotate(90deg);
        width: auto;
        li {
          width: 32px;
          height: 32px;
          transform: rotate(-90deg);
          button {
            border-radius: 50%;
            text-align: center;
            width: 32px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            &::before {
              color: #ccc;
              font-size: 15px;
              font-family: Arial, Helvetica, sans-serif;
              text-shadow: 1px 1px 3px #555;
              width: 32px;
              height: 32px;
              line-height: 30px;
              opacity: 1;
              transition: all 0.4s;
            }
          }
          &:first-child {
            button {
              &::before {
                content: "01";
              }
            }
          }
          &:nth-child(2) {
            button {
              &::before {
                content: "02";
              }
            }
          }
          &:nth-child(3) {
            button {
              &::before {
                content: "03";
              }
            }
          }
          &.slick-active {
            button {
              background: var(--primaryClr);
              &::before {
                color: var(--whiteClr);
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
  .casino_games {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    a {
      position: relative;
      img {
        border-radius: 5px;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(#3f3f4f 10%, #000 100%);
        color: var(--primaryClr);
        font-weight: 700;
        line-height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        opacity: 0;
        border-radius: 5px;
        .game_type {
          font-size: 10px;
          text-transform: uppercase;
          max-width: 90px;
          transition: all 0.4s;
        }
      }
      &:hover {
        .overlay {
          opacity: 0.8;
          .game_type {
            transform: scale(1.2);
          }
        }
      }
    }
  }
  &.matched_bets {
    @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
      display: block;
    }
    @media only screen and (max-width: 991.98px) {
      display: none;
    }
    .accordion {
      .accordion-item {
        background-color: #333;
        padding-bottom: 5px;
        margin-bottom: 10px;
        border: 0;
        border-radius: 5px;
        box-shadow: 0 0 10px rgb(0 0 0 / 60%);
        .accordion-header {
          .accordion-button {
            background: transparent;
            width: 100%;
            text-align: left;
            padding: 5px 10px;
            border: 0;
            border-bottom: 1px solid var(--primaryClr);
            border-radius: 0;
            box-shadow: none;
            img {
              width: 20px;
              margin-right: 10px;
            }
            span {
              color: var(--primaryClr);
              font-size: 13px;
              font-weight: 600;
              line-height: 28px;
            }
            &:hover,
            &:focus {
              background: transparent !important;
            }
            &:after {
              content: none;
            }
          }
        }
        .accordion-body {
          color: var(--whiteClr);
          padding: 0;
          border: 0;
          .bets_box {
            .bet_info {
              .table {
                background: var(--headClr);
                margin-bottom: 0;
                border-collapse: collapse;
                overflow-x: auto;
                border: 1px solid #222;
                thead {
                  background: #222;
                  display: block;
                  tr {
                    th {
                      color: var(--whiteClr);
                      font-size: 12px;
                      font-weight: 600;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 150px;
                      border: 0;
                      &:not(:first-child) {
                        min-width: 60px;
                      }
                      &:first-child {
                        min-width: 35px;
                      }
                      &:last-child {
                        width: 100%;
                      }
                    }
                  }
                }
                tbody {
                  display: block;
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 360px;
                  &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                  }
                  &::-webkit-scrollbar-track {
                    background: #222;
                  }
                  &::-webkit-scrollbar-thumb {
                    background: #333;
                    &:hover {
                      background: #444;
                    }
                  }
                  tr {
                    td {
                      color: var(--whiteClr);
                      font-size: 11px;
                      border-color: #333;
                      &:not(:first-child) {
                        min-width: 60px;
                      }
                      &:first-child {
                        min-width: 35px;
                      }
                      &:last-child {
                        width: 100%;
                      }
                      span {
                        color: var(--bodyClr);
                        width: 35px;
                        text-align: center;
                        display: inline-block;
                        border-radius: 4px;
                        &.lay {
                          background: #72bbef;
                        }
                        &.back {
                          background: #faa8ba;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .no_data {
            color: var(--whiteClr);
            font-size: 20px;
            font-weight: 600;
            width: 100%;
            text-align: center;
          }
        }
        &.live_tv {
          .accordion-body {
            background: var(--bodyClr);
            .live_tv_box {
              height: 215px;
            }
          }
        }
        &.bets {
          margin-bottom: 0px;
        }
      }
    }
  }
}

body.leftbat-opened {
  .leftbarSec {
    transform: translateX(0);
  }
}
.mt--14 {
  .leftbarSec {
    margin-top: -14px;
  }
}
.main {
  .leftbarSec {
    background: var(--secondaryClr);
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 999;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        padding: 10px 0;
        li {
          cursor: pointer;
          border-bottom: 1px solid #333;
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img {
              width: 20px;
              height: auto;
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #333;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #222;
                    display: block;
                    tr {
                      th {
                        color: var(--whiteClr);
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      margin-top: 16px;
      .info_box {
        background: url(../src/assets/images/sports/baccaratnew.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 150px;
        padding: 6px 8px 8px;
        border-radius: 6px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          display: flex;
          justify-content: space-between;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 700;
            height: 16px;
            overflow: hidden;
            font-size: 13px;
            color: rgba(255 255 255 / 0.5);
            &.match-name {
              font-weight: 700;
              height: 16px;
              overflow: hidden;
              white-space: nowrap;
              -webkit-mask-image: linear-gradient(
                90deg,
                rgb(0, 0, 0) 80%,
                rgba(0, 0, 0, 0) 100%
              );
              font-size: 13px;
              color: rgba(255 255 255 / 0.5);
            }
          }
          .time {
            font-weight: 500;
            font-size: 12px;
            height: 16px;
            color: rgba(255 255 255 / 0.5);
          }
        }
        .participants {
          display: flex;
          justify-content: space-between;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 48px;
              height: 48px;
              line-height: 48px;
              border-radius: 50%;
              padding-bottom: 8px;
              margin: 0 auto;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        // background: #222430;

        background: #000;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        box-shadow: 0 7px 15px rgba(0, 0, 0, 0.2);
        &.slick-prev {
          left: 15px;
          z-index: 99;
        }
        &.slick-next {
          right: 15px;
          z-index: 99;
        }
        &.slick-prev::before {
          content: url("./assets/images/sports/left-arrow.png");
          opacity: 1;
          font-size: 16px;
        }
        &.slick-next::before {
          content: url("./assets/images/sports/left-arrow.png");
          transform: rotate(180deg);
          opacity: 1;
          font-size: 16px;
        }
      }
      .slick-dots {
        bottom: -35px;
        li {
          margin: 0 2px;
          padding: 0;
          &.slick-active button:before {
            background: var(--primaryClr) !important;
          }
          button:before {
            font-size: inherit;
            background: #fff;
            width: 20px;
            height: 1px;
            opacity: 1;
          }
        }
      }
    }
  }
  .popular_game_sec {
    background: transparent;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    box-shadow: 0 4px 3px rgba(255, 189, 0, 0.6);
    @media screen and (max-width: 767.98px) {
      padding: 1rem 2rem 0.5rem;
    }
    .head-popular {
      font-weight: 600;
    }
    .game_box {
      margin: 5px;
      a {
        img {
          border-radius: 0.75rem;
        }
      }
    }
  }
  .sports_banner_sec {
    background-color: #181818;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 767.98px) {
      padding: 1rem;
    }
    .img_box {
      img {
        border-radius: 1.5rem;
      }
    }
  }
  .casino_sec {
    background-color: #181818;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      min-width: 20%;
      @media screen and (max-width: 767.98px) {
        margin-bottom: 1rem !important;
      }
      h5 {
        font-weight: 600;
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
      }
      .btn {
        color: var(--secondaryClr);
        font-size: 16px;
        width: 90%;
        min-width: 170px;
        border-radius: 40px;
        @media screen and (max-width: 1199.98px) {
          width: 100%;
        }
        &.casino_btn,
        &.exchange_btn {
          background: #537fe7 !important;
          border-color: #537fe7;
        }
      }
    }
    .games {
      overflow: hidden;
      a {
        img {
          max-width: 95%;
          border-radius: 0.75rem;
        }
      }
    }
  }
  .providers_sec {
    background-color: #181818;
    padding: 2rem;
    margin-bottom: 2.5rem;
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 1.5rem;
    @media screen and (max-width: 1199.98px) {
      padding: 1rem;
    }
    .heading {
      max-width: 300px;
      text-align: center;
      @media screen and (max-width: 1199.98px) {
        margin: 0 auto;
      }
      p {
        color: var(--primaryClr);
      }
    }
    .providers_logo {
      width: calc(100% - 300px);
      @media screen and (max-width: 1199.98px) {
        width: calc(100% - 0px);
      }
      .logo_box {
        margin: 3px;
        img {
          max-width: 120px;
          padding: 3px;
          border-radius: 10px;
        }
      }
    }
  }
  .TopCategories {
    margin: 0 0px 30px;
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
      margin: 0;
      padding: 0;
      background: var(--secondaryClr);
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 20px;
      .nav-item {
        button,
        .anav-link {
          background-size: 30px 30px !important;
          background-position: 27px 10px !important;
          background-repeat: no-repeat !important;
          color: var(--whiteClr);
          font-size: 14px;
          width: 92px;
          height: 100%;
          padding: 46px 5px 5px;
          border: 0;
          border-radius: 0;
          &.active {
            background: var(--bodyClr);
          }
          &#uncontrolled-tab-example-tab-Roulette {
            background-image: url("https://gbet567.com/images/Roulette_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Baccarat {
            background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Andar\ Bahar {
            background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Poker {
            background-image: url("https://gbet567.com/images/Poker_Icons.png");
          }
          &#uncontrolled-tab-example-tab-Blackjack {
            background-image: url("https://gbet567.com/images/Blackjack_icons.png");
          }
          &#uncontrolled-tab-example-tab-TeenPatti {
            background-image: url("https://gbet567.com/images/Teenpatti.png");
          }
          &#uncontrolled-tab-example-tab-32Card {
            background-image: url("https://gbet567.com/images/casino.png");
          }
          &#uncontrolled-tab-example-tab-Seven {
            background-image: url("https://gbet567.com/images/seven.png");
          }
          &#uncontrolled-tab-example-tab-Dices {
            background-image: url("https://gbet567.com/images/dices.png");
          }
          &#uncontrolled-tab-example-tab-Sports {
            background-image: url("../public/images/sportsss.png");
          }
        }
      }
    }
  }
  .mainBanner {
    .slick-slide {
      img {
        width: 100%;
        height: auto;
        max-height: 400px;
        object-fit: cover;
        border-radius: 20px;
      }
      .content {
        bottom: auto;
        font-size: 0.85rem;
        left: 0;
        position: absolute;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 100%;
        z-index: 99;
        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }
          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }
          @media screen and (max-width: 767px) {
            font-size: 1.15rem;
          }
          span {
            color: var(--primaryClr);
            margin: 0 8px;
          }
        }
        p {
          @media screen and (max-width: 767px) {
            font-size: 0.7rem;
          }
        }
      }
    }
    .slick-dots {
      bottom: 65px;
      text-align: right;
      right: 95px;
      li {
        margin: 0 2px;
        padding: 0;
        &.slick-active button:before {
          background: var(--primaryClr) !important;
        }
        button:before {
          font-size: inherit;
          background: #fff;
          width: 20px;
          height: 4px;
          border-radius: 8px;
          opacity: 1;
        }
      }
    }
  }
  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: var(--whiteClr);
    position: relative;
    display: inline-block;
    margin: 20px 0 10px;
    padding-bottom: 5px;
    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: var(--primaryClr);
    }
  }
  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    .link {
      width: 100%;
      background: var(--primaryClr);
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: var(--whiteClr);
      text-transform: uppercase;
      padding: 8px;
    }
  }
  .payment-info {
    background: var(--headClr);
    margin: 0 -1rem;
    padding: 22px 1rem;
    color: var(--whiteClr);
    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 14px;
        fill: var(--whiteClr);
        stroke: var(--whiteClr);
        opacity: 0.6;
      }
    }
    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }
    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      &.btn-outline-secondary {
        border-color: var(--whiteClr);
        color: var(--whiteClr);
      }
    }
  }
  .account-statement {
    input {
      z-index: 9 !important;
    }
    button.calender-icon {
      svg {
        opacity: 0;
        @media only screen and (max-width: 767px) {
          opacity: 1;
        }
      }
    }
    .search-icon {
      button {
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 40px;
      height: 33px;
    }
    h3 {
      border-bottom: 1px solid var(--whiteClr);
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;
    }
    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }
    ul {
      margin: 0 -1rem;
      border-top: 1px solid var(--whiteClr);
      li {
        padding: 22px 1rem;
        border-bottom: 1px solid var(--whiteClr);
        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }
        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }
        .details {
          font-size: 12px;
          font-weight: 300;
        }
        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
    table {
      thead {
        background: var(--blackClr);
        tr {
          th {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
      tbody {
        background: #111111;
        color: #fff;
        tr {
          td {
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid var(--primaryClr);
    }
    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .edit-btn {
    background: var(--whiteClr);
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: var(--whiteClr);
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-info {
    th {
      background: var(--headClr);
      color: var(--whiteClr);
    }
    td {
      background: var(--bodyClr);
      color: var(--whiteClr);
    }
    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}
.tron-qr-section.paytm {
  flex-direction: row-reverse;
}
.tron-qr-code.paytm {
  padding-right: 20px;
}
.note {
  color: red;
}
.note-section {
  margin-top: 5px;
}
.affiliate-link {
  span {
    display: flex;
    flex-direction: column;
    p {
      font-size: 14px;
      margin: 0;
      color: #1ca7f8;
    }
  }
  &:hover {
    p {
      color: #fff !important;
    }
  }
}
.marquee-section {
  margin: 15px 0;
}

.bets_box {
  table {
    thead tr th {
      color: #fff;
      border: 0 !important;
    }
    tbody tr td {
      color: #fff;
    }
  }
}
.outer_popup .modal-footer {
  background-color: var(--secondaryClr);
  border: 0 !important;
}
.btn-login {
  background: transparent;
  color: #1f1f1f;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  padding: 6px 10px;
  border: 0;
  border-color: transparent;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #111 !important;
  }
  @media only screen and (max-width: 767.98px) {
    font-size: 0.65rem;
    width: 65px;
    height: 34px;
    line-height: 34px;
  }
}

.btn-register {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  padding: 6px 10px;
  border: 0;
  border-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #e5a600 !important;
    color: var(--blackClr) !important;
  }
  @media only screen and (max-width: 767.98px) {
    font-size: 0.65rem;
    width: 80px;
    height: 34px;
    line-height: 34px;
  }
}
.sports-section {
  margin-top: -60px;
  padding: 0 25px;
  ul {
    display: flex;
    align-items: center;

    li {
      background-color: var(--secondaryClr);
      position: relative;
      width: calc(25% - 10px);
      margin-left: 20px;
      padding: 8px;
      border-radius: 16px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      overflow: hidden;
      transition: all 0.3s;
      &:first-child {
        margin-left: 0;
      }
      a {
        .img-head {
          padding: 30px;
          background: #0b151e;
          border-radius: 8px;
          img {
            width: 60px;
          }
        }
        .right-content {
          padding-left: 30px;
          h2 {
            position: relative;
            color: #fff;
            padding-left: 0;
            text-align: left;
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
          }
          p {
            color: #ffb900;
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            img {
              display: flex;
              align-items: center;
              margin-top: 8px;
              line-height: 1;
              width: 15px;
              transform: rotate(270deg);
            }
          }
        }
      }
    }
  }
}
.live_dealer_casino {
  padding: 25px;

  &.matchGameSection {
    display: flex;
  }
  h4 {
    a {
      margin-bottom: 25px;
      font-size: 22px;
      font-weight: bold;
      display: block;
      color: rgba(255 255 255 / 0.8);
      position: relative;
      // &::before {
      //   position: absolute;
      //   content: "";
      //   width: 70px;
      //   height: 1px;
      //   background-color: #fff;
      //   bottom: -4px;
      // }
    }
  }
  .gamesSection {
    width: 60%;

    .casino_games {
      ul {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        &::-webkit-scrollbar {
          height: 6px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: transparent;
          border-radius: 10px;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgb(76, 81, 89);
          }
        }
        li {
          // flex: 0 0 auto;
          margin-left: 0.65rem;
          &:first-child {
            margin-left: 0;
          }
          a {
            width: 175px;
            display: inline-block;
            color: rgb(134, 151, 162);
            position: relative;
            padding: 0 5px;
            margin-bottom: 0 !important;
            transition: 0.3s ease-in-out;
            &:hover {
              transform: translateY(-6px);
              box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.3);
            }
            span.like-btn {
              float: right;
              background-color: transparent;
              svg {
                fill: #aab8c2;
              }
            }
            &.active,
            &:hover {
              color: var(--whiteClr);
            }
            &:after {
              position: absolute;
              content: "";
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 10px;
              height: 2px;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &.active {
              &:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .HomeMatchSection {
    width: 40%;
    display: flex;
    align-items: flex-end;
    .live-match-section {
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      color: #fff;
      min-height: 644px;
      padding: 0 8px;
      img {
        transform: rotate(180);
        transition: transform 1s ease-in;
        animation: up-and-down 1.25s linear infinite alternate;
      }
      @keyframes up-and-down {
        0% {
          transform: translateY(0%);
        }

        100% {
          transform: translateY(-20%);
        }
      }
      h4 {
        margin-top: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        img {
          width: 40px;
          margin-right: 8px;
        }
      }
      .team-name-section {
        padding: 15px 8px 30px;

        .TeamSection {
          display: flex;
          justify-content: space-between;
          margin-bottom: 15px;
          .team-name {
            display: flex;
            .teamIcon {
              background: linear-gradient(
                rgb(252 249 249 / 10%) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              width: 30px;
              height: 30px;
              margin-right: 8px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 16px;
              }
            }
            span {
              font-size: 18px;
              font-weight: 700;
            }
          }
          .team-odds {
            background: rgba(255, 255, 255, 0.05);
            padding: 2px 5px;
            min-width: 60px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            text-align: center;
            font-size: 15px;
          }
        }
      }
      .TeamOddsSection {
        .team-country-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h4 {
            margin: 0;
            padding: 0 8px;
            font-size: 13px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
            cursor: pointer;
            &:hover {
              color: #fff;
            }
            &:hover img {
              filter: brightness(100);
            }
            img {
              width: 18px;
            }
          }
          span {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 600;
            font-size: 13px;
          }
        }
        .OddsBox {
          position: relative;
          margin-top: 12px;
          ul {
            display: flex;
            width: 100%;
            li {
              width: 48%;
              margin: 1%;
              a {
                background: #132534;
                width: 100%;
                display: flex;
                border-radius: 6%;
                flex-direction: column;
                align-items: center;
                min-height: 192px;
                padding: 15px;
                font-size: 12px;
                color: var(--whiteClr);
                &:hover {
                  background-color: rgba(19, 37, 52, 0.5);
                }
                .TeamNameImg {
                  margin: 0 auto;
                  .TeamImg {
                    background: linear-gradient(
                      rgba(252, 249, 249, 0.1) 0%,
                      rgba(255, 255, 255, 0) 100%
                    );
                    width: 50px;
                    height: 50px;
                    margin-right: 8px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                      width: 35px;
                    }
                  }
                  span {
                    max-height: 74px;
                    display: block;
                    height: 74px;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 12px;
                  }
                }
                .odds {
                  span {
                    font-size: 14px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
      .place-bet-section {
        margin-top: 16px;
        padding: 0 8px;
        form {
          display: flex;
          div {
            width: 49%;
            margin: 1%;
            input {
              width: 100%;
              background-color: #132534 !important;
              border: 1px solid transparent !important;
              border-radius: 4px;
              height: 40px;
              padding: 8px;
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
            }
          }

          button {
            background: rgba(255, 255, 255, 0.2);
            border: 0;
            height: 40px;
            width: 49%;
            border-radius: 4px;
            margin: 1%;
          }
        }
      }
      .slick-slider button.slick-arrow {
        background-color: #132534;
        border-radius: 50%;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        color: #fff;
        width: 32px;
        top: auto;
        bottom: -55px;
        padding: 0 8px;
        height: 32px;
        &.slick-prev {
          left: 14px;
          &::before {
            content: url("./assets/images/sports/left-arrow.png");
            opacity: 1;
            font-size: 25px;
          }
        }
        &.slick-next {
          right: 8px;
          &::before {
            content: url("./assets/images/sports/left-arrow.png");
            transform: rotate(180deg);
            opacity: 1;
            font-size: 25px;
          }
        }
      }
    }
  }
}

.homePageContent {
  padding: 25px;
  .content {
    h4 {
      font-size: 30px;
      padding-bottom: 8px;
      color: var(--primaryClr);
      font-weight: 700;
    }
    p {
      font-size: 14px;
      margin-bottom: 18px;
    }
  }
}

.slots-section {
  margin: 25px auto 0;
  width: 95%;
  .game-heading {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;

    img {
      margin-right: 10px;
      width: 30px;
      z-index: 99999999999 !important;
      position: relative;
    }
    span {
      color: hsla(0, 0%, 100%, 0.7);
      font-size: 30px;
      font-weight: 600;
    }
  }

  img {
    margin-right: 10px;
    width: 30px;
    z-index: 99999999999 !important;
    position: relative;
  }

  ul {
    display: flex !important;
    flex-wrap: wrap;
    padding: 0;
    li {
      width: 19%;
      margin: 0.5%;
      position: relative;

      &:hover .casino_overlay {
        inset: 0 0 0 0;
        img {
          height: 80px !important;
          width: 80px !important;
        }
      }
      img {
        border-radius: 8px;
        width: 100% !important;
        height: 100% !important;
      }
      .casino_overlay {
        align-items: center;
        background: linear-gradient(
          0deg,
          rgba(37, 35, 152, 0.49),
          rgba(37, 35, 152, 0.49)
        );
        border-radius: 10px;
        content: "";
        z-index: 999999999999999999999;
        display: flex;
        inset: 0 0 0 100%;
        justify-content: center;
        position: absolute;
        transition: all 0.9s;
        img {
          border-radius: 10px;
          height: 0;
          width: 0 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 767.98px) {
  .valuesDeposit {
    align-items: center;
    h3 {
      font-size: 16px;
    }
    .copyValue {
      font-size: 15px !important;
    }
  }
  .mobile-game-scroll {
    display: flex;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #585858;
    }
    &::-webkit-scrollbar-thumb {
      background: #2d3136;
      &:hover {
        background: #2d3136;
      }
    }
  }
}
.gatewaySection {
  max-width: 800px;
  margin: 40px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: #d1d1d1;
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #000;
        &.active {
          background: var(--primaryClr);
          border-radius: 15px 15px 0px 0px;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    background: var(--secondaryClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border: 0.5px solid #414141;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #414141;
          text-align: center;
          img {
            width: 100%;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 14px;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
.gatewayForm {
  margin: 25px;

  input {
    border-color: #4a5d6b;
    padding: 14px 20px;
  }
  button {
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    min-width: 120px;
    padding: 9px 30px;
    text-transform: uppercase;
  }
}
.heading {
  display: flex;
  justify-content: space-between;
  background: var(--primaryClr);
  padding: 10px;
  align-items: center;
  h4 {
    margin: 0;
  }
}
.valuesDeposit {
  border: 1px var(--bs-primary) solid;
  margin-top: 20px;
  color: var(--bs-primary);
  background: #ffffff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  /* width: 50%; */
  justify-content: center;
  h3 {
    font-weight: 400;
  }
  .copyValue {
    font-size: 20px;
    margin-left: 30px;
    text-align: center;
    div {
      font-size: 8px;
      font-weight: 700;
    }
  }
}