@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #222a34;
  --headClr: #101520;
  --bodyClr: #1a1e29;
  --whiteClr: #ffffff;
  --blackClr: #000000;
  --lightClr: rgba(255, 255, 255, 0.6);
  --siteRedClr: #af2f2f;
}
.light:root {
  --primaryClr: #ffbd00;
  --secondaryClr: #999;
  --headClr: rgba(0, 0, 0, 0.175);
  --bodyClr: #ddd;
  --whiteClr: #000000;
  --blackClr: #ffffff;
}

img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 767.98px) {
  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 340px !important;
  }
  .slots-section ul li {
    width: 49% !important;
  }
  .header .navbar .navbar-toggler,
  .header .headerRight ul li svg,
  .header .top-head {
    display: none !important;
  }
  .header .nav-head {
    border-radius: 0 !important;
    margin: 0 !important;
  }
  .header {
    padding-top: 0 !important;
  }
  .btn-register,
  .btn-login {
    margin: 0 !important;
    font-size: 13px !important;
    width: 90px !important;
    justify-content: center !important;
  }
  .live_dealer_casino .HomeMatchSection .live-match-section {
    min-height: inherit !important;
  }
  .footer {
    margin-top: 0 !important;
  }
  .footer .upi-provider ul {
    flex-wrap: wrap !important;
  }
  .footer .upi-provider ul li img {
    max-height: 16px !important;
    margin: 6px 10px !important;
  }
  .footer .footer-links {
    flex-wrap: wrap;
  }
  .footer .footer-links .betlinks {
    width: 50%;
    margin-top: 15px;
    margin-left: 0 !important;
    margin-right: 15px !important;
  }
  .footer .footer-links .betlinks h4 {
    font-size: 15px;
  }
  .live_dealer_casino .HomeMatchSection {
    width: 100% !important;
    margin-top: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .live_dealer_casino .HomeMatchSection {
    width: 30px;
  }
  .live_dealer_casino.matchGameSection {
    flex-direction: column;
  }
  .live_dealer_casino .gamesSection .casino_games ul li a {
    width: 50% !important;
  }
  .live_dealer_casino .gamesSection {
    width: 100% !important;
  }
  .sports-section ul li {
    padding: 5px !important;
    width: 100px !important;
    margin-left: 13px !important;
    border-radius: 3px !important;
    overflow: visible !important;
  }
  .sports-section ul li:first-child {
    margin-left: 0 !important;
  }
  .sports-section ul li a .right-content {
    padding: 0 !important;
  }
  .sports-section ul li a .img-head {
    width: 100% !important;
    height: 60px !important;
    border-radius: 3px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sports-section ul li a {
    flex-direction: column !important;
  }
  .sports-section ul li a .right-content p {
    display: none !important;
  }
  .sports-section ul li a .img-head img {
    width: 40px !important;
  }
  .sports-section {
    padding: 0 0 0 8px !important;
    margin-top: -18px !important;
  }
  .main .mainBanner .slick-dots {
    right: 25px !important;
    bottom: 15px !important;
  }
  .sports-section ul {
    overflow-x: scroll;
  }
  .sports-section ul li a .right-content h2 {
    text-align: center;
    font-size: 13px !important;
    padding-top: 15px;
  }
  .main .games_tab ul li a .imgBox img {
    padding: 0.75rem !important;
  }
  .page-details
    .game-points.bookmaker
    ul
    li.bookmaker-new
    .status-btns
    .info-block {
    display: none !important;
  }
  .page-details
    .game-points.bookmaker
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(4),
  .page-details
    .game-points
    ul
    li.bookmaker-new
    .status-btns
    .info-block:nth-child(5) {
    display: flex !important;
    width: 50% !important;
  }
  .betslip-content.inline_betslip .auto-stack ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul li {
    margin-bottom: 0.5rem;
  }
  .match_details_container
    .math_info
    .live-tv-mob
    button.live-tv-btn.collapse_container {
    background: #333 !important;
    border: 1px solid #333 !important;
    border-radius: 5px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6) !important;
    width: 30px;
    height: 30px;
  }
  .custom-tabs .tab-pane .game-listing .match-info,
  .sports_page .tab-pane .game-listing .match-info {
    display: block !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .match-name,
  .sports_page .tab-pane .game-listing .match-info .match-name {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing,
  .sports_page .tab-pane .game-listing .match-info .listing {
    padding-bottom: 5px !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing li,
  .sports_page .tab-pane .game-listing .match-info .listing li {
    margin-right: 3px !important;
    width: 100% !important;
  }
  .page-details .game-top-area .date,
  .page-details .game-top-area .range {
    display: none !important;
  }
  .page-details .game-points ul li {
    border-bottom: 1px solid #2f3940 !important;
    display: flex !important;
    padding-left: 0.5rem !important;
  }
  .page-details .game-points ul li .team-name {
    color: #fff !important;
    color: var(--whiteClr) !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    overflow: hidden !important;
    padding: 0 0.25rem 0 0 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: calc(100% - 150px) !important;
  }
  .page-details .game-points ul li .status-btns {
    align-items: center !important;
    display: flex !important;
    width: 140px !important;
  }
  .page-details .game-points ul li .status-btns .info-block:first-child {
    display: none !important;
    margin-left: 0 !important;
  }
  .page-details .game-points ul li .status-btns .info-block {
    display: none !important;
    width: inherit !important;
  }

  .page-details .game-points ul li .status-btns .info-block:nth-child(3),
  .page-details .game-points ul li .status-btns .info-block:nth-child(4) {
    display: flex !important;
    width: 50% !important;
  }
  .page-details .game-points ul li .status-btns .info-block.no:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .page-details .game-points .back-lay-section .back-lay-points {
    width: 146px;
  }
  .page-details
    .game-points
    .back-lay-section
    .back-lay-points
    ul
    li:nth-child(3),
  .page-details
    .game-points
    .back-lay-section
    .back-lay-points
    ul
    li:nth-child(4) {
    display: flex !important;
  }
  .middleContentSec
    .page-details
    .game-points
    .back-lay-section
    .back-lay-points,
  .middleContentSec
    .page-details
    .game-points
    .back-lay-section
    .back-lay-points
    ul {
    background-color: #3b5160 !important;
  }
  .page-details .game-points .back-lay-section .back-lay-points ul li {
    display: none !important;
  }
  .page-details .title span {
    background-color: inherit !important;
  }
  #chat-widget-container {
    bottom: 60px !important;
  }
  .whtasapp-all-page {
    bottom: 145px !important;
  }
  .logo {
    max-width: 100px;
  }
}

@media only screen and (max-width: 480px) {
  .page-details .AllMarket_section .game-points ul li .team-name {
    display: block !important;
    width: calc(100% - 140px);
  }
}

/* .header {
  padding: 15px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--headClr);
  width: 100%;
}
.header .logo {
  max-width: 125px;
}
.navbar-toggler {
  display: none;
}
.header .navbar {
  padding: 0;
}
.header .navbar .navbar-collapse {
  background: var(--secondaryClr);
  margin-left: 15px;
  padding: 5px;
  border-radius: 22px;
}
.header .navbar .navbar-collapse .navbar-nav a {
  color: var(--whiteClr);
  padding: 0 12px;
}
.header .navbar .navbar-collapse .navbar-nav a svg,
.header .navbar .navbar-collapse .navbar-nav a img {
  fill: var(--whiteClr);
  width: 20px;
  height: 20px;
}
.header .navbar .navbar-collapse .navbar-nav a:hover {
  opacity: 0.8;
}
.header .headerRight {
  display: flex;
  align-items: center;
  fill: var(--whiteClr);
}
.header .headerRight .path-stroke {
  stroke: var(--whiteClr);
}
.header .headerRight .funds ul {
  background: var(--secondaryClr);
  border-radius: 25px;
  padding: 4px 12px;
  margin-right: 12px;
}
.header .headerRight .funds ul li {
  position: relative;
  margin: 0 10px;
}
.header .headerRight .funds ul li:first-child:after {
  border-right: 1px solid var(--whiteClr);
  content: "";
  position: absolute;
  height: 16px;
  top: 3px;
  right: -11px;
}
.header .headerRight .funds ul li a {
  color: var(--whiteClr);
}
.header .headerRight .funds ul li a {
  padding-left: 8px;
}
.header .headerRight ul {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.header .headerRight ul li {
  margin: 0 5px;
  position: relative;
}
.header .headerRight ul li .notification-count {
  width: 12px;
  height: 12px;
  background: var(--siteRedClr);
  color: var(--whiteClr);
  border-radius: 50%;
  position: absolute;
  right: -2px;
  top: -2px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .headerRight .menu-btn {
  padding: 0;
  background: none;
  border: none;
}
.header .headerRight .menu-btn:hover,
.header .headerRight .menu-btn:focus {
  background: none !important;
  border-color: transparent !important;
}
.header .headerRight .menu-btn svg {
  width: 20px;
  height: auto;
  margin-left: 5px;
  fill: var(--primaryClr);
} */

* {
  padding: 0;
  margin: 0;
}

:root {
  --primaryClr: #169a85;
  --secondaryClr: #1c1d21;
  --whiteClr: #fff;
  --darkClr: #000;
  --grayClr: #a4a3a8;
  --lightGrayClr: #e4e4e4;
  --siteGreenClr: #508334;
  --siteYellowClr: #edbf2e;
}

/*=============== Common CSS ===============*/
html,
body {
  background: var(--whiteClr);
  font-size: 14px;
}
body:not(.mobile)::-webkit-scrollbar,
.nice-scrollbar::-webkit-scrollbar {
  width: 5px;
}
body:not(.mobile)::-webkit-scrollbar-thumb,
.nice-scrollbar::-webkit-scrollbar-thumb {
  background: #919595;
  border-radius: 0;
  border: solid 1px #919595;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
body:not(.mobile)::-webkit-scrollbar-track,
.nice-scrollbar::-webkit-scrollbar-track {
  background: #e4e4e4;
  margin: 2px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
button:focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none;
}
ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  transition: all 0.5s ease-in-out;
}
a:hover {
  text-decoration: none;
}
hr {
  border-color: var(--siteGreenClr);
}

/* betslip */
.betslip-wrapper {
  background: var(--headClr);
  display: flex;
  max-width: 350px;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  right: 0;
  z-index: 999;
  box-shadow: 0 0px 8px rgba(255, 189, 0, 0.12);
}
.betslip-wrapper .betslip-head {
  border-top-left-radius: 0.5rem;
  box-shadow: 0 2px 18px rgb(0 0 0 / 25%);
  min-width: 100%;
  width: 100%;
  margin-left: 0 !important;
  right: 0;
  top: 0;
  z-index: 98;
}
.betslip-wrapper .betslip .betslip_close_btn {
  border-bottom: 1px solid var(--secondaryClr) !important;
  text-align: right !important;
}
.betslip-wrapper .closebtn {
  font-size: 18px;
  color: var(--whiteClr);
  margin-left: 0;
}
.betslipStart ul {
  border-bottom: 1px solid var(--secondaryClr);
  justify-content: center;
}
.betslipStart ul li a {
  background: transparent !important;
  color: var(--whiteClr) !important;
  font-weight: 600;
  text-align: center;
  border: 0 !important;
  padding: 0.5rem 1rem;
  font-size: 14px;
}
.betslipStart ul li a.active {
  color: var(--primaryClr) !important;
}
.betslipStart .betsTsbs ul.nav-tabs li {
  flex: 1;
}
.betslipStart .betslip-content {
  border-bottom: 0;
  padding: 1rem;
}
.betslipStart .betslip-content .match-name {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.betslipStart .betslip-content .match-name p {
  color: #fff;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0;
}
.betslipStart .betslip-content .match-name p .info {
  display: inline-block;
  margin-right: 0.75rem;
}
.betslipStart .betslip-content .match-name .betslip-close {
  background: #fff;
  border-radius: 50%;
  color: #080325;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  height: 16px;
  line-height: 13px;
  text-align: center;
  width: 16px;
}
.betslipStart .betslip-content .match-result {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
}
.betslipStart .betslip-content .match-result .team-name {
  color: var(--primaryClr);
  font-size: 0.9rem;
  font-weight: 600;
}
.betslipStart .betslip-content .match-result .team-name span {
  color: #fff;
}
.betslipStart .betslip-content .match-result .odds {
  background: var(--bodyClr);
  border: 1px solid var(--secondaryClr);
  border-radius: 25px;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  height: 100%;
  min-width: 60px;
  padding: 0.35rem 0.5rem;
  text-align: center;
}
.betslipStart .betslip-content .stack-win-value {
  align-items: center;
  display: flex;
  padding-top: 1rem;
}
.betslipStart .betslip-content .stack-win-value .stack,
.betslipStart .betslip-content .stack-win-value .winning {
  flex: 1 1;
}
.betslipStart .betslip-content .stack-win-value .stack .form-control {
  -webkit-animation: glowingGreen 1s infinite;
  animation: glowingGreen 1s infinite;
  background: #220a49;
  border: 1px solid var(--secondaryClr);
  color: var(--whiteClr);
  max-width: 120px;
}
.betslipStart .betslip-content .stack-win-value .winning p {
  color: #999;
  margin-bottom: 0;
  margin-left: 1rem;
}
.betslipStart .betslip-content .stack-win-value .winning p span {
  color: var(--whiteClr);
}
.betslipStart .betslip-content .auto-stack {
  padding: 1rem 0;
  border-bottom: 1px solid var(--secondaryClr);
}
.betslipStart .betslip-content .auto-stack ul {
  align-items: center;
  display: flex;
  border-bottom: 0;
}
.betslipStart .betslip-content .auto-stack ul li {
  flex: 1 1;
  margin-right: 0.5rem;
}
.betslipStart .betslip-content .auto-stack ul li .stack-btn {
  background: var(--secondaryClr);
  color: #fff;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.betslipStart .betslip-content .bet-now {
  margin-top: 15px;
}
.betslipStart .betslip-content .bet-now .place-btn {
  background: var(--primaryClr);
  color: var(--blackClr);
  font-size: 1.15rem;
  font-weight: 600;
  width: 100%;
}
.betslipStart .open-bets {
  padding-top: 1rem;
}
.betslipStart .open-bets form {
  padding-left: 1rem;
}
.betslipStart .open-bets form .form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.betslipStart .open-bets form .form-check .form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.betslipStart .open-bets form label {
  color: #fff;
  cursor: pointer;
}
.betslipStart .open-bets .my-bets .bet-heading {
  background: #1d1a3e;
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.betslipStart .open-bets .my-bets .bet-heading li:first-child {
  font-size: 0.9rem;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.betslipStart .open-bets .my-bets .bet-heading li {
  color: #ddd;
  font-size: 0.85rem;
  text-align: right;
  width: 25%;
}
.betslipStart .open-bets .my-bets body {
  height: auto !important;
  max-height: calc(100vh - 118px);
  overflow-y: auto;
}
.betslipStart .open_bets {
  padding-top: 1rem;
}
@media screen and (max-height: 450px) {
  .betslip-wrapper {
    padding-top: 15px;
  }
  .betslip-wrapper a {
    font-size: 18px;
  }
}
.betslip-wrapper {
  right: 0;
}
.Toastify__toast-container {
  z-index: 999999999 !important;
}
@media only screen and (max-width: 575px) {
  .DepositModal .modal-content .modal-body .deposit-section-head {
    flex-wrap: wrap;
  }
  .DepositModal .modal-content .modal-body .deposit-section-head form,
  .DepositModal .modal-content .modal-body .deposit-section-head form input {
    width: 100%;
  }
}
.loading_img2 {
  position: absolute;
  width: 15%;
  height: auto;
  left: 40%;
  top: 200px;
}
